<template>
  <v-sheet>
    <Dialog :common-dialog="dialogInvoice" :dialog-width="dialogWidth">
      <template v-slot:title>
        <div class="d-flex align-center justify-content-between w-100">
          <div>
            Invoice Schedule ({{ invoiceSchedule?.length }}) {{ getDuration() }}
          </div>
          <div v-if="false">
            <div class="text-h6">
              Total Occurrences:
              <span class="font-weight-bold ms-2"
                >{{ invoiceSchedule?.length }}/{{
                  invoiceSchedule?.length
                }}</span
              >
            </div>
            <div class="text-h6">
              Total Quotation Amount:
              <span class="font-weight-bold ms-2"
                >$ {{ invoiceSchedule[0]?.billing_amount }}</span
              >
            </div>
          </div>
          <v-spacer></v-spacer>
          <table>
            <tr>
              <td
                class="m-0 px-4 font-size-16 font-weight-500 custom-grey-border light-orange-background"
              >
                <!-- Discount and  -->GST are not applied to the billing Amount
                shown in the invoice*
              </td>
            </tr>
          </table>
        </div>
      </template>
      <template v-slot:body>
        <v-container>
          <table width="100%">
            <thead>
              <tr class="">
                <th class="grey lighten-4 color-custom-blue"></th>
                <th class="grey lighten-4 color-custom-blue">Invoice Date</th>
                <th class="grey lighten-4 color-custom-blue">Day</th>
                <th class="grey lighten-4 color-custom-blue">Due Date</th>
                <th class="grey lighten-4 color-custom-blue">
                  Billing Amount (in SGD)
                </th>
                <!--  <th
                  class="grey lighten-4 color-custom-blue"
                
                ></th> -->
                <th class="grey lighten-4 color-custom-blue">
                  Total Others Item
                </th>
                <th
                  class="grey lighten-4 color-custom-blue"
                  v-if="
                    quoteInvoiceParameters.invoice_amount_type == 'each_amount'
                  "
                >
                  Action
                </th>
              </tr>
            </thead>
            <template v-if="invoiceSchedule?.length > 0">
              <tr
                v-for="(row, index) in invoiceSchedule"
                :key="index"
                :class="{
                  'visit-row-hover': row.isEdited,
                  'orange lighten-5': index % 2 !== 0,
                }"
              >
                <td
                  class="font-size-14 font-weight-500"
                  align="center"
                  valign="middle"
                  width="50"
                >
                  {{ index + 1 }}.
                </td>
                <td width="200">
                  <DatePicker
                    v-model="row.startDate"
                    key="visit-show-schedule-start-date"
                    id="visit-show-schedule-start-date"
                    :min-date="todayDate"
                    :max-date="maxDate"
                    placeholder="Start Date"
                    v-on:change="
                      updateDay($event, index);
                      row.isEdited = 1;
                    "
                  />
                </td>
                <td width="200">
                  <v-text-field
                    readonly
                    solo
                    flat
                    dense
                    :disabled="!row.status"
                    filled
                    hide-details
                    :value="row.daysOfWeek"
                  ></v-text-field>
                </td>
                <td width="200">
                  <DatePicker
                    v-model="row.dueDate"
                    key="visit-show-schedule-due-date"
                    id="visit-show-schedule-due-date"
                    :min-date="row.dueDate"
                    placeholder="Due Date"
                  />
                </td>
                <td width="200">
                  <v-text-field
                    key="visit-show-billing-amount-date"
                    id="visit-show-billing-amount-date"
                    placeholder="Billing Amount"
                    type="number"
                    solo
                    flat
                    dense
                    filled
                    :readonly="
                      quoteInvoiceParameters.invoice_amount_type ==
                      'total_amount'
                        ? true
                        : false
                    "
                    hide-details
                    v-model="row.billing_amount"
                    v-on:keypress="limitDecimal($event, row.billing_amount)"
                    @input="updateBillingAmounts(index)"
                  ></v-text-field>
                </td>
                <td width="200">
                  <div class="d-flex align-center pb-1">
                    <div class="d-flex align-center cursor-pointer">
                      <div
                        class="text-h6 color-custom-blue me-1"
                        v-if="row?.otherItem?.length > 1"
                      >
                        {{ row?.otherItem.length - 1 }}+
                      </div>
                      <v-text-field
                        v-if="nonNullItamLength(row?.otherItem) > 0"
                        readonly
                        solo
                        flat
                        dense
                        filled
                        hide-details
                        v-model="row.toatalAmt"
                      ></v-text-field>
                    </div>
                    <div class="d-flex">
                      <v-icon
                        v-if="nonNullItamLength(row?.otherItem) > 0"
                        color="cyan"
                        size="28"
                        v-on:click="editOtherItem(index)"
                        >mdi-pencil-circle</v-icon
                      >
                      <v-icon
                        v-if="nonNullItamLength(row?.otherItem) > 0"
                        color="red"
                        size="28"
                        v-on:click="deleteItemSchedule(index)"
                        >mdi-delete-circle</v-icon
                      >
                      <v-btn
                        v-if="nonNullItamLength(row?.otherItem) == 0"
                        width="70"
                        depressed
                        variant="outlined"
                        class="mx-2 custom-grey-border custom-bold-button white--text"
                        color="cyan"
                        v-on:click="addOtherItem(index)"
                      >
                        <v-icon small>mdi-plus</v-icon> Item
                      </v-btn>
                    </div>
                  </div>
                </td>
                <td
                  v-if="
                    quoteInvoiceParameters.invoice_amount_type == 'each_amount'
                  "
                >
                  <template
                    v-if="
                      quoteInvoiceParameters.invoice_amount_type ==
                      'each_amount'
                    "
                  >
                    <v-icon
                      color="cyan"
                      size="28"
                      v-on:click="addSchedule(index)"
                      >mdi-plus-circle</v-icon
                    >
                    <v-icon
                      color="red"
                      size="28"
                      v-on:click="removeSchedule(index)"
                      >mdi-minus-circle</v-icon
                    >
                  </template>
                  <!-- <template v-else>
                    <v-icon
                      color="cyan"
                      size="28"
                      v-on:click="addSchedule(index)"
                      >mdi-plus-circle</v-icon
                    >
                    <v-icon
                      color="red"
                      size="28"
                      v-on:click="removeSchedule(index)"
                      >mdi-minus-circle</v-icon
                    >
                  </template> -->
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="6" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no invoice schedule at the moment.
                  </p>
                </td>
              </tr>
            </template>
          </table>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="validateSchedule()"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="$emit('close', true)"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="otherItemDialog" :dialog-width="dialogWidth2">
      <template v-slot:title>Add Other Item</template>
      <template v-slot:body>
        <v-container>
          <div class="custom-grey-border">
            <table width="100%">
              <thead>
                <tr>
                  <th class="grey lighten-4" width="50%">
                    <div class="color-custom-blue">Title *</div>
                  </th>
                  <th class="grey lighten-4">
                    <div class="color-custom-blue">Selling/Unit Cost</div>
                  </th>
                  <th class="grey lighten-4">
                    <div class="color-custom-blue">Quantity</div>
                  </th>
                  <th class="grey lighten-4">
                    <div class="color-custom-blue">Total</div>
                  </th>
                  <th class="grey lighten-4"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in otherItem"
                  :key="index"
                  :class="{
                    'cyan lighten-5': index % 2 !== 0,
                  }"
                >
                  <td>
                    <v-expansion-panels class="cepfli">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <TextField
                            id="item-title"
                            dense
                            :class="item.product ? '' : 'error--text'"
                            filled
                            placeholder="Title"
                            solo
                            flat
                            counter="50"
                            color="cyan"
                            :maxlength="250"
                            v-model="item.product"
                          />
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-0">
                          <TextAreaField
                            auto-grow
                            dense
                            filled
                            color="cyan"
                            placeholder="Description"
                            solo
                            flat
                            row-height="20"
                            counter="250"
                            v-model="item.description"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </td>
                  <td valign="top">
                    <div>
                      <v-text-field
                        class="line-item-header-text px-0"
                        placeholder="Selling/Unit Cost"
                        dense
                        filled
                        solo
                        flat
                        type="number"
                        color="cyan"
                        v-model="item.rate"
                        v-on:keyup="calculate_total()"
                        v-on:keypress="limitDecimal($event, item.rate)"
                      />
                    </div>
                  </td>
                  <td valign="top">
                    <div>
                      <v-text-field
                        class="line-item-header-text px-0"
                        placeholder="Quantity"
                        dense
                        filled
                        solo
                        flat
                        type="number"
                        color="cyan"
                        v-model="item.quantity"
                        v-on:keyup="calculate_total()"
                        v-on:keypress="isNumber($event)"
                      />
                    </div>
                  </td>
                  <td valign="top">
                    <div>
                      <v-text-field
                        class="line-item-header-text px-0"
                        placeholder="Total"
                        dense
                        filled
                        solo
                        flat
                        type="number"
                        color="cyan"
                        readonly
                        v-model="item.total"
                        v-on:keyup="calculate_total()"
                        v-on:keypress="limitDecimal($event, item.total)"
                      />
                    </div>
                  </td>
                  <td valign="top">
                    <div class="d-flex mt-2">
                      <v-icon color="cyan" @click="addMoreItem"
                        >mdi-plus-circle</v-icon
                      >
                      <v-icon color="red" @click="removeItem(index)"
                        >mdi-minus-circle</v-icon
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="otherItemDialog = false"
        >
          close
        </v-btn>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="saveItem()"
        >
          Add
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import DatePicker from "@/view/components/QDatePicker.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { SET_INVOICE_RECURRING_DATE_FOR_QUOTE } from "@/core/services/store/invoice.module";
import moment from "moment-timezone";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import { v4 as uuidv4 } from "uuid";
import { toBtxNumber } from "@/core/services/common.service";
import CommonMixin from "@/core/plugins/common-mixin.js";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  name: "job-ctx-show-schedule",
  mixins: [CommonMixin],

  data() {
    return {
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      invoiceSchedule: [],
      otherItemDialog: false,
      is_created: false,
      otherItem: [
        {
          status: true,
          index: uuidv4(),
          uuid: uuidv4(),
          id: null,
          image: {},
          order: 0,
          serial_no: null,
          eq_model: null,
          location: null,
          group: null,
          discount: 0,
          type: "other",
          is_child: false,
          parent_uuid: null,
          has_child: true,
          has_property: true,
          property: {},
          group_primary: false,
          discount_value: 0,
          discount_type: 1,
          to_equipment: 0,
          product: null,
          project_price: 0,
          threshold_price: 0,
          product_id: 0,
          product_type: "other",
          additional_item: "invoice",
          description: null,
          has_warranty: false,
          warranty: {},
          rate: 0,
          selling_cost: 0,
          quantity: 1,
          uom: null,
          total: 0,
        },
      ],
      itemIndex: 0,
    };
  },
  props: {
    dialogInvoice: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialogInvoice: {
      immediate: true,
      deep: true,
      handler(param) {
        if (param) {
          this.$nextTick(() => {
            this.invoiceSchedule = this.isEdit
              ? this.dbInvoiceUpdateSchedule
              : this.invoiceScheduleOutput;
            if (
              this.quoteInvoiceParameters?.invoice_amount_type == "each_amount"
            ) {
              this.updateBillingAmounts(0);
            }
          });
        }
      },
    },
  },
  methods: {
    nonNullItamLength(items) {
      // Filter the array to get only items where product is not null
      if (items) {
        const filteredItems = items.filter((item) => item.product !== null);
        // Return the length of the filtered array
        return filteredItems.length;
      }
    },
    calculate_total() {
      for (let i = 0; i < this.otherItem?.length; i++) {
        let total = this.accountingToFixed(
          toBtxNumber(this.otherItem[i].quantity) *
            toBtxNumber(this.otherItem[i].rate)
        );
        this.otherItem[i].total = total;
      }
    },
    deleteItemSchedule(index) {
      this.invoiceSchedule[index].otherItem = [];
      this.invoiceSchedule[index].toatalAmt = 0;
      this.is_created = false;
    },
    addOtherItem(index) {
      (this.otherItem = [
        {
          status: true,
          index: uuidv4(),
          uuid: uuidv4(),
          id: null,
          image: {},
          order: 0,
          serial_no: null,
          eq_model: null,
          location: null,
          group: null,
          discount: 0,
          type: "other",
          is_child: false,
          parent_uuid: null,
          has_child: true,
          has_property: true,
          property: {},
          group_primary: false,
          discount_value: 0,
          discount_type: 1,
          to_equipment: 0,
          product: null,
          project_price: 0,
          threshold_price: 0,
          product_id: 0,
          product_type: "other",
          additional_item: "invoice",
          description: null,
          has_warranty: false,
          warranty: {},
          rate: 0,
          selling_cost: 0,
          quantity: 1,
          uom: null,
          total: 0,
        },
      ]),
        (this.otherItemDialog = true);
      this.itemIndex = index;
    },
    editOtherItem(index) {
      this.itemIndex = index;
      this.otherItem = this.invoiceSchedule[index].otherItem;
      this.otherItemDialog = true;
    },
    addMoreItem() {
      let len = this.otherItem.length + 1;
      this.otherItem.push({
        status: true,
        index: uuidv4(),
        uuid: uuidv4(),
        id: null,
        image: {},
        order: len,
        serial_no: null,
        eq_model: null,
        location: null,
        group: null,
        discount: 0,
        type: "other",
        is_child: false,
        parent_uuid: null,
        has_child: true,
        has_property: true,
        property: {},
        group_primary: false,
        discount_value: 0,
        discount_type: 1,
        to_equipment: 0,
        product: null,
        project_price: 0,
        threshold_price: 0,
        product_id: 0,
        product_type: "other",
        additional_item: "invoice",
        description: null,
        has_warranty: false,
        warranty: {},
        rate: 0,
        selling_cost: 0,
        quantity: 1,
        uom: null,
        total: 0,
      });
    },
    removeItem(index) {
      if (this.otherItem && this.otherItem?.length > 1) {
        this.otherItem.splice(index, 1);
      }
    },
    saveItem() {
      if (this.invoiceSchedule && this.invoiceSchedule?.length > 0) {
        for (
          let i = 0;
          i < this.invoiceSchedule[this.itemIndex].otherItem.length;
          i++
        ) {
          if (!this.invoiceSchedule[this.itemIndex].otherItem[i]?.product) {
            ErrorEventBus.$emit("update:error", "Item title is required");
            return false;
          }
        }
        this.invoiceSchedule[this.itemIndex].otherItem = this.otherItem;

        let filteredItems = this.invoiceSchedule[this.itemIndex].otherItem;

        const totalSum = filteredItems.reduce((accumulator, currentValue) => {
          return accumulator + Number(currentValue.total);
        }, 0);
        this.invoiceSchedule[this.itemIndex].toatalAmt = totalSum;
        this.is_created = true;
        this.otherItemDialog = false;
      }
    },
    updateBillingAmounts(changedIndex) {
      if (this.invoiceSchedule && this.invoiceSchedule.length) {
        let totalAmount = this.vCalculations?.total_non_text_amount
          ? this.vCalculations?.total_non_text_amount
          : 0;
        const remainingAmount =
          totalAmount - this.invoiceSchedule[changedIndex]?.billing_amount;
        const otherRows = this.invoiceSchedule.filter(
          (_, index) => index !== changedIndex
        );
        const splitAmount = remainingAmount / otherRows.length;
        otherRows.forEach((row) => {
          row.billing_amount = splitAmount;
        });
      }
    },
    addSchedule() {
      const _schedule = this.lodash.cloneDeep(this.invoiceSchedule);
      _schedule.push({
        daysOfWeek: "",
        startDateTime: "",
        endDateTime: "",
        startDate: "" /* _startDate */,
        endDate: "",
        startTime: "",
        endTime: "",
        status: 1,
        isEdited: 0,
        isPrimary: 0,
        isDisabled: 0,
        billing_amount: 0,
      });
      this.invoiceSchedule = _schedule;
    },
    removeSchedule(index) {
      if (this.invoiceSchedule?.length > 0) {
        this.invoiceSchedule.splice(index, 1);
      }
    },
    updateDay(date, index) {
      this.invoiceSchedule[index].daysOfWeek = moment(date).format("dddd");
    },
    getDuration() {
      //
    },
    validateSchedule() {
      this.$store.commit(
        SET_INVOICE_RECURRING_DATE_FOR_QUOTE,
        this.invoiceSchedule
      );
      this.$emit("next", true);
    },
  },
  components: {
    Dialog,
    DatePicker,
    TextAreaField,
  },
  computed: {
    ...mapGetters([
      "invoiceScheduleOutput",
      "quoteInvoiceParameters",
      "vCalculations",
      "invoiceRecurringSchedule",
      "dbInvoiceUpdateSchedule",
      "isEdit",
    ]),
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    dialogWidth2() {
      return toSafeInteger((document.body.clientWidth / 100) * 60);
    },
    maxDate() {
      if (this.invoiceRecurringSchedule.end_mode == 3) {
        return this.invoiceRecurringSchedule.end_date;
      }
      return "";
    },
    // nonNullProductLength() {
    //   // Filter the array to get only items where product is not null
    //   const filteredItems = this.otherItem.filter(item => item.product !== null);
    //   // Return the length of the filtered array
    //   return filteredItems.length;
    // },
    // totalProductSum() {
    //   // Filter the array to get only items where product is not null
    //   const filteredItems = this.otherItem.filter(item => item.product !== null);
    //   // Sum the total values of the filtered items
    //   return filteredItems.reduce((sum, item) => sum + item.total, 0);
    // }
  },
  mounted() {},
};
</script>
