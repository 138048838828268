<template>
  <v-row class="mx-0">
    <v-col :md="invoiceShowCalendar ? 4 : 12" class="py-0">
      <v-row>
        <v-col :md="invoiceShowCalendar ? 12 : 12">
          <v-row class="custom-grey-border">
            <v-col
              md="12"
              class="light-gray-background custom-border-bottom d-flex"
            >
              <div
                style="font-size: 19px"
                class="text-uppercase font-weight-700 color-custom-blue"
              >
                Invoice Recurring Schedule
              </div>
              <v-spacer></v-spacer>
              <div v-if="isQuotation">
                <v-btn
                  v-on:click="scheduleDialog = true"
                  depressed
                  tile
                  :disabled="jobLoading"
                  value="3"
                  color="cyan white--text"
                  >Show Schedule</v-btn
                >
              </div>
            </v-col>

            <v-col :md="invoiceShowCalendar ? 5 : 5">
              <div class="d-flex align-center">
                <label
                  class="mr-1 mb-0"
                  style="width: 90px"
                  for="visit-one-off-start-date"
                  >Start Date</label
                >
                <DatePicker
                  :disabled="jobLoading"
                  v-on:change="
                    updateScheduleState(
                      'dbRecurringSchedule.start_date',
                      $event
                    );
                    updateEndDate(true);
                  "
                  :value="invoiceRecurringSchedule.start_date"
                  key="visit-one-off-start-date"
                  id="visit-one-off-start-date"
                  placeholder="Start Date"
                />
              </div>
            </v-col>
            <v-col :md="7">
              <div class="d-flex align-center">
                <div>
                  <label
                    class="mr- mb-0"
                    style="width: 110px"
                    for="visit-one-off-end-time"
                    >Repeat every</label
                  >
                </div>
                <!-- @keyup="validateAndUpdate($event)" -->
                <v-text-field
                  v-on:keypress="isNumber($event)"
                  type="number"
                  dense
                  filled
                  hide-details
                  placeholder="Duration"
                  :class="customError ? 'error--text' : ''"
                  solo
                  flat
                  @keyup="validateAndUpdate()"
                  v-on:change="
                    updateScheduleState(
                      'dbRecurringSchedule.recurring_duration_new',
                      $event
                    )
                  "
                  v-model="invoiceRecurringSchedule.recurring_duration_new"
                  color="cyan"
                  class="mr-2"
                />
                <v-select
                  dense
                  filled
                  class="schedule-type-list"
                  :disabled="jobLoading"
                  item-color="cyan"
                  :items="recurringInvoiceScheduleTypeList"
                  placeholder="Schedule Type"
                  solo
                  flat
                  :value="invoiceRecurringSchedule.type"
                  return-object
                  v-on:change="
                    updateWeekDays(
                      $event,
                      invoiceRecurringSchedule.recurring_duration_new
                    ),
                      validateAndUpdate()
                  "
                  hide-details
                  color="cyan"
                />
              </div>
              <div v-if="customError" class="text-center">
                <span class="text--red"> {{ customError }}</span>
              </div>
            </v-col>
            <!-- <v-col :md="2">
                <v-select
                  dense
                  filled
                  class="schedule-type-list"
                  :disabled="jobLoading"
                  item-color="cyan"
                  :items="recurringInvoiceScheduleTypeList"
                  placeholder="Schedule Type"
                  solo
                  flat
                  :value="invoiceRecurringSchedule.type"
                  return-object
                  v-on:change="updateWeekDays($event,invoiceRecurringSchedule.recurring_duration_new),validateAndUpdate()"
                  hide-details
                  color="cyan"
                />
              </v-col> -->
            <v-col
              :md="invoiceShowCalendar ? 12 : 12"
              v-if="invoiceRecurringSchedule?.type?.group == 'weekly'"
            >
              <div
                class="mt-2"
                v-if="
                  invoiceRecurringSchedule?.type?.group == 'monthly' && false
                "
              >
                <v-radio-group
                  :disabled="jobLoading"
                  class="mt-0"
                  hide-details
                  mandatory
                  :value="invoiceRecurringSchedule.recurring_pattern"
                  v-on:change="
                    updateScheduleState(
                      'dbRecurringSchedule.recurring_pattern',
                      $event
                    )
                  "
                >
                  <v-radio
                    :disabled="jobLoading"
                    :value="1"
                    hide-details
                    color="cyan"
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'disabled-rw pointer-events-none':
                            invoiceRecurringSchedule.recurring_pattern != 1,
                        }"
                        class="d-flex align-center font-weight-500"
                      >
                        <label class="text-center my-0 ml-2"
                          >Selection by Date</label
                        >
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    :disabled="jobLoading"
                    :value="2"
                    hide-details
                    color="cyan"
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'disabled-rw pointer-events-none':
                            invoiceRecurringSchedule.recurring_pattern != 2,
                        }"
                        class="d-flex align-center font-weight-500"
                      >
                        <div style="width: 150px">
                          <v-select
                            dense
                            filled
                            :disabled="jobLoading"
                            item-color="cyan"
                            :items="invoiceDurationList"
                            item-text="long_name"
                            item-value="id"
                            placeholder="Select Day"
                            v-model="invoiceRecurringSchedule.week_day"
                            v-on:change="updateSchedule()"
                            solo
                            flat
                            hide-details
                            color="cyan"
                          />
                        </div>
                        <div style="width: 150px">
                          <v-select
                            dense
                            filled
                            :disabled="jobLoading"
                            item-color="cyan"
                            item-text="long_name"
                            item-value="id"
                            :items="invoiceWeekDays"
                            placeholder="Select Week Day"
                            v-model="invoiceRecurringSchedule.week_count"
                            v-on:change="updateSchedule()"
                            solo
                            flat
                            hide-details
                            color="cyan"
                          />
                        </div>
                        <template
                          v-if="
                            invoiceRecurringSchedule?.type?.value ==
                            'twice_a_month'
                          "
                        >
                          <div class="ml-10" style="width: 150px">
                            <v-select
                              dense
                              filled
                              :disabled="jobLoading"
                              item-color="cyan"
                              :items="invoiceDurationList"
                              item-text="long_name"
                              item-value="id"
                              placeholder="Select Day"
                              v-model="invoiceRecurringSchedule.second_week_day"
                              v-on:change="updateSchedule()"
                              solo
                              flat
                              hide-details
                              color="cyan"
                            />
                          </div>
                          <div style="width: 150px">
                            <v-select
                              dense
                              filled
                              :disabled="jobLoading"
                              item-color="cyan"
                              item-text="long_name"
                              item-value="id"
                              :items="invoiceWeekDays"
                              placeholder="Select Week Day"
                              v-model="
                                invoiceRecurringSchedule.second_week_count
                              "
                              v-on:change="updateSchedule()"
                              solo
                              flat
                              hide-details
                              color="cyan"
                            />
                          </div>
                        </template>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>

              <v-row v-if="invoiceRecurringSchedule?.type?.group == 'weekly'">
                <v-col
                  class="py-0 my-0"
                  md="2"
                  v-for="(weekday, index) in invoiceWeekDays"
                  :key="`job-weekday-${index}`"
                >
                  <v-checkbox
                    color="cyan"
                    :disabled="jobLoading || isDisabled(weekday.id)"
                    :label="
                      invoiceShowCalendar
                        ? weekday.short_name
                        : weekday.short_name
                    "
                    :input-value="
                      +invoiceRecurringSchedule.weeks.includes(weekday.id)
                    "
                    :true-value="1"
                    :false-value="0"
                    v-on:change="updateSelection(weekday.id)"
                    hide-details
                    class="mt-0"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col :md="invoiceShowCalendar ? 12 : 12">
              <v-radio-group
                :disabled="jobLoading"
                class="mt-0"
                row
                id="id"
                hide-details
                mandatory
                :value="invoiceRecurringSchedule.end_mode"
                v-on:change="
                  updateScheduleState('dbRecurringSchedule.end_mode', $event)
                "
              >
                <div
                  class="pa-1 mr-2 mb-2 rounded-sm custom-radio-input mt-0"
                  :class="
                    invoiceRecurringSchedule.end_mode == 2 ? 'active' : ''
                  "
                  style="width: 48%"
                >
                  <v-radio
                    :disabled="jobLoading"
                    :value="2"
                    hide-details
                    color="cyan"
                  >
                    <template v-slot:label>
                      <div
                        class="d-flex align-center"
                        :class="{
                          'disabled-rw pointer-events-none':
                            invoiceRecurringSchedule.end_mode != 2,
                        }"
                      >
                        <div>End after</div>
                        <div class="mx-2">
                          <v-text-field
                            dense
                            :disabled="
                              jobLoading ||
                              invoiceRecurringSchedule.end_mode != 2
                            "
                            filled
                            v-model="invoiceRecurringSchedule.occurrence"
                            type="number"
                            placeholder="occurrences"
                            v-on:change="
                              updateScheduleState(
                                'dbRecurringSchedule.occurrence',
                                $event
                              )
                            "
                            @keyup="validateAndUpdateOccurrences()"
                            :class="customErrorOcc ? 'error--text' : ''"
                            solo
                            flat
                            color="cyan"
                          />
                        </div>
                        <div>occurrences</div>
                      </div>
                    </template>
                  </v-radio>
                  <div v-if="customErrorOcc" class="text-center">
                    <span class="text--red"> {{ customErrorOcc }}</span>
                  </div>
                </div>
                <div
                  class="pa-1 mb-2 rounded-sm custom-radio-input mt-0"
                  :class="
                    invoiceRecurringSchedule.end_mode == 3 ? 'active' : ''
                  "
                  style="width: 48%"
                >
                  <v-radio
                    :disabled="jobLoading"
                    :value="3"
                    hide-details
                    color="cyan"
                    v-on:click="
                      updateEndDate(
                        true,
                        invoiceRecurringSchedule.recurring_duration_new
                      )
                    "
                    style="max-width: 319px !important"
                  >
                    <template v-slot:label>
                      <div
                        class="d-flex align-center"
                        :class="{
                          'disabled-rw pointer-events-none':
                            invoiceRecurringSchedule.end_mode != 3,
                        }"
                      >
                        <div class="mr-2">End after</div>
                        <DatePicker
                          :disabled="
                            jobLoading || invoiceRecurringSchedule.end_mode != 3
                          "
                          :value="
                            invoiceRecurringSchedule.end_mode == 3
                              ? invoiceRecurringSchedule.end_date
                              : ''
                          "
                          v-on:change="
                            updateScheduleState(
                              'dbRecurringSchedule.end_date',
                              $event
                            )
                          "
                          :min-date="invoiceRecurringSchedule.start_date"
                          key="visit-recurring-end-date"
                          placeholder="Select End Date"
                        />
                      </div>
                    </template>
                  </v-radio>
                </div>
              </v-radio-group>
            </v-col>
            <v-col md="12">
              <table width="100%">
                <tr>
                  <td
                    class="m-0 px-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                  >
                    {{ invoiceRecurringSchedule.message }}
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="invoiceShowCalendar && false" md="8">
      <Calendar
        :key="`job-recurring-calendar-${scheduleKey}`"
        :show-calendar="invoiceShowCalendar"
      ></Calendar>
    </v-col>
    <ShowScheduleRecurring
      :dialog-invoice="scheduleDialog"
      v-on:next="nextStepSchedule()"
      :key="`quote-show-schedule-${uniqueId}`"
      v-on:close="scheduleDialog = false"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "@/view/components/main-job/QDatePicker.vue";
import ShowScheduleRecurring from "@/view/components/InvoiceScheduleRecurringForQute.vue";
import {
  UPDATE_DEFAULT_DATE,
  GET_RECURRING_DATE,
  UPDATE_CALENDAR_ACTION,
  UPDATE_SCHEDULE_STATE,
  UPDATE_ERROR_MESSAGE,
  UPDATE_SCHEDULE_LIST_QUOTE,
  UPDATE_ERROR_MESSAGE_OCC,
} from "@/core/services/store/invoice.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-ctx-recurring-schedule",
  data() {
    return {
      /*  isEdit : false, */
      scheduleConfirmDialog: false,
      scheduleDialog: false,
      loadingDialog: false,
      uniqueId: Number(new Date()),
      uniqueCId: Number(new Date()),
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      scheduleKey: Number(new Date()),
      timeOut: null,
      dialogTimeOut: null,
      dbPriventiveId: 0,
    };
  },
  props: {
    isQuotation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    validateAndUpdateOccurrences() {
      let occurrence = this.lodash.toSafeInteger(
        this.invoiceRecurringSchedule.occurrence
      );
      let customMessageOcc = null;
      if (occurrence > 99) {
        customMessageOcc = "The maximum allowed occurrence value is 99";
        this.$store.dispatch(UPDATE_ERROR_MESSAGE_OCC, customMessageOcc);
      } else {
        customMessageOcc = null;
        this.$store.dispatch(UPDATE_ERROR_MESSAGE_OCC, customMessageOcc);
      }
    },
    validateAndUpdate() {
      let value = this.lodash.toSafeInteger(
        this.invoiceRecurringSchedule.recurring_duration_new
      );
      let customMessage = null;
      if (value == 0 && this.invoiceRecurringSchedule?.type?.value != "daily") {
        customMessage = "The minimum allowed value is 1";
      } else if (!value) {
        customMessage = "This field cannot be empty";
      } else if (
        (value < 7 && this.invoiceRecurringSchedule?.type?.value == "daily") ||
        value == 0
      ) {
        customMessage = "The minimum allowed value is 7";
      } else if (
        value > 365 &&
        this.invoiceRecurringSchedule?.type?.value == "daily"
      ) {
        customMessage = "The maximum allowed value is 365";
      } else if (
        value > 12 &&
        this.invoiceRecurringSchedule?.type?.value == "monthly"
      ) {
        customMessage = "The maximum allowed value is 12";
      } else if (
        value > 52 &&
        this.invoiceRecurringSchedule?.type?.value == "weekly"
      ) {
        customMessage = "The maximum allowed value is 52";
      } else if (
        value > 1 &&
        this.invoiceRecurringSchedule?.type?.value == "annually"
      ) {
        customMessage = "The maximum allowed value is 1";
      } else {
        customMessage = null;
        this.$store.dispatch(UPDATE_ERROR_MESSAGE, customMessage);
      }
      if (customMessage) {
        this.$store.dispatch(UPDATE_ERROR_MESSAGE, customMessage);
      }
    },
    updateSelection(dayId) {
      const isSelected = this.invoiceRecurringSchedule.weeks.includes(dayId);
      if (isSelected) {
        this.invoiceRecurringSchedule.weeks =
          this.invoiceRecurringSchedule.weeks.filter(
            (selectedDay) => selectedDay !== dayId
          );
      } else {
        if (
          this.invoiceRecurringSchedule.weeks.length < this.allowedSelections()
        ) {
          this.invoiceRecurringSchedule.weeks.push(dayId);
        }
      }
      this.$nextTick(() => {
        this.updateSchedule();
      });
    },
    allowedSelections() {
      const selectedFrequencyData = this.recurringInvoiceScheduleTypeList.find(
        (item) => item.value === this.invoiceRecurringSchedule?.type?.value
      );
      return selectedFrequencyData ? selectedFrequencyData?.max_allowed : 0;
    },
    isDisabled(dayId) {
      return (
        this.allowedSelections() ===
          this.invoiceRecurringSchedule.weeks.length &&
        !this.invoiceRecurringSchedule.weeks.includes(dayId)
      );
    },
    nextStepSchedule() {
      this.scheduleDialog = false;
    },
    showSchedule() {
      this.$store.dispatch(GET_RECURRING_DATE);
      this.uniqueId = Number(new Date());
      if (!this.isEdit) {
        this.generateInvoiceScheduleQuote();
      }
    },
    generateInvoiceScheduleQuote() {
      if (this.isQuotation) {
        let invoiceArrayTotelAmt = [];
        let total = 0;
        if (
          this.quoteInvoiceParameters?.invoice_amount_type == "total_amount"
        ) {
          total = this.vCalculations?.total_non_text_amount;
        } else {
          total =
            this.vCalculations?.total_non_text_amount /
            Number(this.invoiceRecurringSchedule?.occurrence);
        }
        invoiceArrayTotelAmt = this.invoiceScheduleOutput?.map((item) => ({
          daysOfWeek: item?.daysOfWeek,
          startDate: item?.startDate,
          dueDate: this.dueDate(item?.startDate),
          billing_amount: total,
          status: 1,
          isEdited: 0,
          isDisabled: 0,
          isPrimary: 0,
          otherItem: item?.otherItem ?? [],
          toatalAmt: item?.toatalAmt ?? 0,
        }));

        this.$store.commit(UPDATE_SCHEDULE_LIST_QUOTE, {
          key: "dbScheduleOutput",
          value: invoiceArrayTotelAmt,
        });
      }
    },
    dueDate(date) {
      let day = 15;
      if (this.quoteInvoiceParameters?.payment_due == 2) {
        day = 15;
      } else if (this.quoteInvoiceParameters?.payment_due == 3) {
        day = 30;
      } else if (this.quoteInvoiceParameters?.payment_due == 4) {
        day = 45;
      }
      let dueData = moment(date).add(day, "days").format("YYYY-MM-DD");
      return dueData;
    },
    openDialog() {
      if (this.invoiceScheduleOutput.length) {
        this.loadingDialog = true;
        this.$store.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbScheduleOutput",
          value: this.invoiceRawScheduleOutput,
        });
        clearTimeout(this.dialogTimeOut);
        this.dialogTimeOut = setTimeout(() => {
          this.scheduleDialog = true;
          this.loadingDialog = false;
          this.scheduleConfirmDialog = false;
        }, 200);
      }
    },
    formatDate(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD MMMM YYYY");
      }
    },
    updateWeekDays(param, duration) {
      this.updateScheduleState("dbRecurringSchedule.occurrence", 1);
      this.updateScheduleState("dbRecurringSchedule.weeks", []);
      this.updateScheduleState("dbRecurringSchedule.type", param);
      this.updateEndDate(true, duration);
    },
    updateEndDate(force, duration) {
      let startDateTime = moment(
        `${this.invoiceRecurringSchedule.start_date} ${this.invoiceRecurringSchedule.start_time}`
      );
      let endDateTime = moment(
        `${this.invoiceRecurringSchedule.end_date} ${this.invoiceRecurringSchedule.end_time}`
      );

      if (force === true || endDateTime.isSameOrBefore(startDateTime)) {
        let endDate = null;
        switch (this.invoiceRecurringSchedule?.type?.value) {
          case "daily":
            endDate = startDateTime.add(duration, "day").format("YYYY-MM-DD");
            break;
          case "weekly":
            endDate = startDateTime.add(duration, "day").format("YYYY-MM-DD");
            break;
          case "twice_a_week":
            endDate = startDateTime.add(3, "day").format("YYYY-MM-DD");
            break;
          case "fort_nightly":
            endDate = startDateTime.add(14, "day").format("YYYY-MM-DD");
            break;
          case "thrice_a_week":
            endDate = startDateTime.add(2, "day").format("YYYY-MM-DD");
            break;
          case "monthly":
            endDate = startDateTime.add(duration, "month").format("YYYY-MM-DD");
            break;
          case "twice_a_month":
            endDate = startDateTime.add(1, "month").format("YYYY-MM-DD");
            break;
          case "bi_monthly":
            endDate = startDateTime.add(12, "month").format("YYYY-MM-DD");
            break;
          case "quarterly":
            endDate = startDateTime.add(12, "month").format("YYYY-MM-DD");
            break;
          case "bi_annually":
            endDate = startDateTime.add(12, "month").format("YYYY-MM-DD");
            break;
          case "annually":
            endDate = startDateTime.add(duration, "year").format("YYYY-MM-DD");
            break;
        }
        let formatDateEndDate = moment(endDate)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        this.updateScheduleState(
          "dbRecurringSchedule.end_date",
          formatDateEndDate
        );
      }
    },
    updateSchedule() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.showSchedule();
      }, 200);
    },
    updateDefaultDate() {
      this.$nextTick(() => {
        this.$store.dispatch(UPDATE_DEFAULT_DATE, "recurring");
      });
    },
    updateScheduleState(key, value) {
      this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
      this.$store.commit(UPDATE_SCHEDULE_STATE, {
        key: "isEdit",
        value: false,
      });
      this.updateDefaultDate();
      this.updateSchedule();
    },
    updateCalendar() {
      this.$store.commit(UPDATE_CALENDAR_ACTION);
    },
    addTime(row) {
      let endTime = moment(row.start_time, "hh:mm A")
        .add(60, "minutes")
        .format("hh:mm A");
      this.updateScheduleState("dbRecurringSchedule.end_time", endTime);
    },
  },
  components: {
    DatePicker,
    ShowScheduleRecurring,
  },
  mounted() {
    this.updateSchedule();
    this.updateDefaultDate();
    const relatedId = Number(this.$route?.query?.edit ?? 0);

    if (
      this.invoiceRecurringSchedule?.type?.group == "daily" &&
      relatedId == 0
    ) {
      this.updateScheduleState("dbRecurringSchedule.recurring_duration_new", 7);
    }
  },
  created() {
    const relatedId = Number(this.$route?.query?.edit ?? 0);
    if (relatedId > 0) {
      this.$store.commit(UPDATE_SCHEDULE_STATE, {
        key: "isEdit",
        value: true,
      });
      this.$store.commit(UPDATE_SCHEDULE_LIST_QUOTE, {
        key: "dbScheduleOutput",
        value: this.dbInvoiceUpdateSchedule,
      });
    }
  },
  computed: {
    ...mapGetters([
      "invoiceDefaultStartedAt",
      "invoiceDefaultFinishedAt",
      "invoiceDurationList",
      "invoiceShowCalendar",
      "recurringInvoiceScheduleTypeList",
      "invoiceWeekDays",
      "invoiceRecurringSchedule",
      "jobLoading",
      "invoiceScheduleOutput",
      "invoiceRawScheduleOutput",
      "customError",
      "vCalculations",
      "quoteInvoiceParameters",
      "dbInvoiceUpdateSchedule",
      "isEdit",
      "customErrorOcc",
    ]),
    updatedDate() {
      return moment(this.invoiceRecurringSchedule.start_time, "hh:mm A")
        .add(60, "minutes")
        .format("hh:mm A");
    },
    todayTime() {
      if (
        Number(moment(this.invoiceRecurringSchedule.start_date).format("mm")) >
        0
      ) {
        let startTime = moment(this.invoiceRecurringSchedule.start_date).add(
          60,
          "minutes"
        );
        return `${startTime.format("hh")}:00 ${startTime.format("A")}`;
      }
      return moment(this.invoiceRecurringSchedule.start_date).format("hh:mm A");
    },
  },
};
</script>
